<template>
  <div class="security-wrapper">
    <h2>Security</h2>
    <div class="security-container">
      <!-- <div class="auth_container">
        <div class="auth_container_left">
          <h3>Authentication</h3>
          <div class="auth_left_tips">
            Authenticate to enhance account security, increase withdrawal limit
            and grow your trading privileges.
          </div>
        </div>
        <div class="auth_container_right">
          <a-button class="go_btn_g" type="primary" @click="goAuth"
            >Update</a-button
          >
        </div>
      </div> -->
      <div class="title_tips">2FA authentication</div>
      <div class="auth_container">
        <div class="auth_container_left auth_container_left_three">
          <div class="auth_container_left_left">
            <img src="@/assets/email_cir.svg" alt="" />
          </div>
          <div class="auth_container_left_right">
            <h3>Email</h3>
            <div class="auth_left_tips">
              For logging in,saving,retrieving passwords,security verification
            </div>
          </div>
        </div>
        <div class="auth_container_right">
          <a-button type="primary" class="go_btn_dis" :disabled="true"
            >Enabled</a-button
          >
        </div>
      </div>
      <div class="auth_container">
        <div class="auth_container_left auth_container_left_three">
          <div class="auth_container_left_left">
            <img src="@/assets/google_cat.svg" alt="" />
          </div>
          <div class="auth_container_left_right">
            <h3>Google Authentication</h3>
            <div class="auth_left_tips">
              For login, password management and security verification
            </div>
          </div>
        </div>
        <div class="auth_container_right">
          <a-button type="primary" class="go_btn" @click="goGoogleVerify">{{
            gaEnable && gaStatus ? "Disable" : "Enable"
          }}</a-button>
        </div>
      </div>
      <div class="title_tips">Password</div>
      <div class="auth_container">
        <div class="auth_container_left auth_container_left_three">
          <div class="auth_container_left_left">
            <img src="@/assets/password_cir.svg" alt="" />
          </div>
          <div class="auth_container_left_right">
            <h3>Password</h3>
            <div class="auth_left_tips">Used to protect account security</div>
          </div>
        </div>
        <div class="auth_container_right">
          <a-button type="primary" class="go_btn" @click="goPassword"
            >Change</a-button
          >
        </div>
      </div>
    </div>
    <a-modal
      v-model="verifyGoogleVisible"
      title="Verify"
      :footer="null"
      :maskClosable="false"
      centered
    >
      <a-form :form="verifyGoogleAuthForm" @submit="handleVerifyGoogleSubmit">
        <a-form-item label="Verify Google Authenticator">
          <a-input
            class="google_input"
            placeholder="Google Authenticator Code"
            size="large"
            v-decorator="[
              'google_code',
              {
                rules: [
                  {
                    required: true,
                    len: 6,
                    message:
                      'Please enter the six-digit code from Google Authenticator',
                  },
                ],
              },
            ]"
          />
        </a-form-item>
        <a-form-item>
          <a-button
            class="goToBtn"
            html-type="submit"
            type="primary"
            :loading="enAbleLoading"
          >
            Confirm
          </a-button>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { getGaStatus, enAbleMailVerify } from '@/utils/api';

export default {
  name: 'Security',
  props: {
    changeDefaultMenu: {
      type: Function,
      require: false,
    },
    changeShower: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      verifyGoogleAuthForm: this.$form.createForm(this, {
        name: 'verifyGoogleAuthFormName',
      }),
      defaultPage: 'Security',
      gaStatus: null,
      gaEnable: 0,
      verifyGoogleVisible: false,
      enAbleLoading: false,
    };
  },
  created() {
    const mail = localStorage.getItem('inst_mail');
    getGaStatus(mail).then((res) => {
      if (res.code === 0) {
        this.gaStatus = res.result.GABound;
        this.gaEnable = res.result.ga_enable;
      }
    });
  },
  methods: {
    goAuth() {
      this.changeDefaultMenu('identification');
    },
    goPassword() {
      this.changeShower('password');
    },
    goGoogleVerify() {
      if (this.gaStatus) {
        if (this.gaEnable === 0) {
          const params = {
            enable: 1,
          };
          enAbleMailVerify(params).then((res) => {
            if (res.code === 0) {
              this.$message.success('Success');
              this.gaEnable = 1;
              this.verifyGoogleVisible = false;
            }
          });
        } else {
          this.verifyGoogleVisible = true;
        }
      } else {
        this.changeShower('googleAuth');
      }
    },
    handleVerifyGoogleSubmit(e) {
      e.preventDefault();
      this.verifyGoogleAuthForm.validateFields((err, values) => {
        this.enAbleLoading = true;
        if (!err) {
          const params = {
            'ga-code': values.google_code,
            enable: 0,
          };
          enAbleMailVerify(params).then((res) => {
            this.enAbleLoading = false;
            if (res.code === 0) {
              this.$message.success('Success');
              this.gaEnable = 0;
              this.verifyGoogleVisible = false;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.security-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  min-height: 800px;
  background: #fff;
  padding: 60px;
  padding-bottom: 40px;
  @media (max-width: 960px) {
    height: auto;
    padding: 12px;
  }
  h2 {
    font-size: 40px;
    line-height: 56px;
  }
  .security-container {
    margin-top: 20px;
    .auth_container {
      display: flex;
      background: rgba(255, 142, 0, 0.1);
      border-radius: 8px;
      padding: 30px;
      justify-content: space-between;
      margin-top: 20px;
      @media (max-width: 960px) {
        flex-direction: column;
      }
      .auth_container_left {
        display: flex;
        flex-direction: column;
        .auth_container_left_left {
          margin-right: 10px;
          img {
            width: 40px;
          }
        }
        h3 {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .auth_left_tips {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
          opacity: 0.6;
        }
      }
      .auth_container_left_three {
        flex-direction: row;
      }
      .auth_container_right {
        height: 46px;
        @media (max-width: 960px) {
          text-align: center;
          margin-top: 12px;
        }
        .go_btn_g {
          height: 46px;
          width: 115px;
          background: #ff8e00;
          border: 1px solid #ff8e00;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
        .go_btn_dis {
          height: 46px;
          width: 115px;
          background: #d9d9d9;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #979797;
        }
        .go_btn {
          height: 46px;
          width: 115px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .title_tips {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      margin-top: 40px;
    }
  }
}
.goToBtn {
  border-radius: 8px;
  height: 40px;
  width: 100%;
  margin-top: 20px;
}
</style>
