<template>
  <div class="changePassword-wrapper">
    <div class="backContainer" @click="backBtn">
      <a-icon type="arrow-left" />
    </div>
    <h2>Change Password</h2>
    <div class="register-container">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="Current Password" has-feedback>
          <a-input
            v-decorator="[
              'old_password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your current password!',
                  },
                ],
              },
            ]"
            type="password"
          />
        </a-form-item>
        <a-form-item label="New Password" has-feedback>
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please enter your new password!',
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              },
            ]"
            type="password"
          />
        </a-form-item>
        <a-form-item label="Confirm Password" has-feedback>
          <a-input
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your new password!',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>
        <a-form-item class="btnContainer">
          <a-button class="goToBtn" html-type="submit"> Confirm </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { submitChangePassword } from '@/utils/api';

export default {
  name: 'ChangePassword',
  props: {
    changeShower: {
      type: Function,
      require: false,
    },
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      localStorageMail: '',
      mail: '',
      password: '',
    };
  },
  created() {
    if (localStorage.getItem('inst_money_access_token')) {
      this.isLogin = true;
      this.localStorageMail = localStorage.getItem('inst_mail');
    } else {
      this.$router.push({
        path: '/',
        query: {
          login: false,
        },
      });
    }
  },
  methods: {
    backBtn() {
      this.changeShower('security');
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            old_password: values.old_password,
            password: values.password,
          };
          submitChangePassword(params).then((res) => {
            this.$message.success(res.msg);
          });
        }
      });
    },
    handleConfirmBlur(e) {
      const { value } = e.target;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const { form } = this;
      if (value && value !== form.getFieldValue('password')) {
        callback('Two passwords that you enter is inconsistent!');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const { form } = this;
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    },
  },
};
</script>

<style lang="scss" scoped>
.changePassword-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.06);
  min-height: 800px;
  background: #fff;
  position: relative;
  padding: 60px;
  @media (max-width: 960px) {
    height: auto;
  }
  .backContainer {
    position: absolute;
    font-size: 18px;
    cursor: pointer;
    z-index: 999;
  }
  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-left: 30px;
  }
  .register-container {
    margin-top: 40px;
    .ant-input {
      height: 48px;
    }
    .btnContainer {
      margin-top: 240px;
      text-align: center;
      @media (max-width: 960px) {
        margin-top: 20px;
      }
      .goToBtn {
        background: #0045ff;
        border-radius: 8px;
        height: 48px;
        width: 400px;
        color: #fff;
        @media (max-width: 960px) {
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 960px) {
    width: 100%;
    flex-direction: column;
    .register-container {
      padding: 20px;
    }
  }
}
</style>
